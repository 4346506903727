// @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    padding: 20px 4%;
    background-color: #fff;
}

a {
    text-decoration: none;
    color: #000;
}

// Scrollbar
::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    background-color: #c0c3c3;
}

::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #11171a;
    border-radius: 10px;
}